import React from "react";
import Header from "./components/Header";
import Card from "./components/Card";
import Footer from "./components/Footer";
import items from "./assets/items";
import { useAtom } from "jotai";
import { langAtom } from "./atom";

import "./css/skeleton.css";
import "./css/normalize.css";

function App() {
  const [lang] = useAtom(langAtom);
  return (
    <div className="App">
      <Header></Header>
      <div className="container row">
        {items.map((item, i) => {
          return (
            <Card
              key={i}
              i={i}
              title={lang === "en" ? item.title : item.title_zh}
              subtitle={lang === "en" ? item.subtitle : item.subtitle_zh}
              link={item.link}
              cover={item.image}
            ></Card>
          );
        })}
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
