import React from "react";
import { useAtom } from "jotai";
import { langAtom } from "../atom";
import SunIcon from "./Icons/SunIcon";
import MoonIcon from "./Icons/MoonIcon";
import LangEn from "./Icons/LangEn";
import LangZh from "./Icons/LangZh";
import avatar from "../assets/avatar.png";
import "../css/skeleton.css";
import "../css/normalize.css";
import "../css/components.css";

function Header() {
  const [theme, setTheme] = React.useState(
    () => document.body.getAttribute("data-theme") ?? "light"
  );
  const [lang, setLang] = useAtom(langAtom);

  React.useEffect(() => {
    document.body.setAttribute("data-theme", theme);
  }, [theme, lang]);
  const handleSwitchTheme = () => {
    setTheme(isDark ? "light" : "dark");
  };
  const handleSwitchLang = () => {
    setLang(lang === "en" ? "zh" : "en");
  };
  const isDark = theme === "dark";
  const isEn = lang === "en";
  return (
    <div className="Header container">
      <div className="ten columns Header__inner">
        <img src={avatar} alt="avatar" />
        &nbsp;&nbsp;&nbsp;
        <h2>
          <b>_MyKeyVans. </b>
        </h2>
      </div>
      <button className="switch-lang-button" onClick={handleSwitchLang}>
        { isEn ? <LangEn /> : <LangZh />}
      </button>
      <button className="switch-theme-button" onClick={handleSwitchTheme}>
        {isDark ? <MoonIcon /> : <SunIcon />}
      </button>
    </div>
  );
}

export default Header;
