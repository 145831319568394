import * as React from "react";

const LangEn = (props) => (
  <svg
    viewBox="0 0 24 24"
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M12 19a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm-5.5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm11 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
    <path
      d="M12 18.5c.716 0 1.424.466 1.732 1 .308.534.358 1.38 0 2-.33.572-1.071 1-1.732 1-.716 0-1.424-.466-1.732-1-.308-.534-.358-1.38 0-2 .33-.572 1.071-1 1.732-1Zm-.866 1.5c-.219.38-.197.659 0 1 .197.341.427.5.866.5.411 0 .661-.144.866-.5.219-.38.197-.659 0-1-.197-.341-.427-.5-.866-.5-.411 0-.661.144-.866.5ZM6.5 18.5c.716 0 1.424.466 1.732 1 .308.534.358 1.38 0 2-.33.572-1.071 1-1.732 1-.716 0-1.424-.466-1.732-1-.308-.534-.358-1.38 0-2 .33-.572 1.071-1 1.732-1ZM5.634 20c-.219.38-.197.659 0 1 .197.341.427.5.866.5.411 0 .661-.144.866-.5.219-.38.197-.659 0-1-.197-.341-.427-.5-.866-.5-.411 0-.661.144-.866.5ZM17.5 18.5c.716 0 1.424.466 1.732 1 .308.534.358 1.38 0 2-.33.572-1.071 1-1.732 1-.716 0-1.424-.466-1.732-1-.308-.534-.358-1.38 0-2 .33-.572 1.071-1 1.732-1Zm-.866 1.5c-.219.38-.197.659 0 1 .197.341.427.5.866.5.411 0 .661-.144.866-.5.219-.38.197-.659 0-1-.197-.341-.427-.5-.866-.5-.411 0-.661.144-.866.5ZM13.5 3.5h6v3h-2.112c-.798 2.241-1.8 3.982-3.274 5.75 1.499 1.347 2.854 2.011 4.776 2.618l.459.145-.804 2.894-.493-.156A16.96 16.96 0 0 1 12 14.391c-1.831 1.647-3.704 2.617-6.052 3.359l-.493.156-.804-2.893.459-.145c1.922-.607 3.365-1.435 4.776-2.618-1.162-1.32-1.823-2.444-2.572-4.036l-.335-.712h3.326l.142.262A13.718 13.718 0 0 0 12 10.098c1.224-1.405 1.622-2.22 2.179-3.598H4.5v-3h6v-2h3Zm-2-1v2h-6v1h10.135l-.257.677a16.986 16.986 0 0 1-3 5.027l-.377.436-.378-.436a16.085 16.085 0 0 1-1.91-2.702H8.572c.653 1.251 1.273 2.206 2.392 3.469l.33.373-.372.331a14.893 14.893 0 0 1-5.052 2.99l.27.97c2.053-.71 3.781-1.695 5.525-3.28L12 13.05l.335.305c1.744 1.585 3.471 2.571 5.525 3.281l.27-.97a14.912 14.912 0 0 1-5.052-2.991l-.372-.331.33-.373a17.408 17.408 0 0 0 3.521-6.129l.113-.342h1.83v-1h-6v-2Z"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M14.997 15.191H9.27l-.851 2.323h-3.22l6.785-16.307h.23l6.808 16.307h-3.128Zm-.851-2.231-1.679-4.37-.391-1.288-.368 1.288-1.61 4.37Z"
      style={{
        whiteSpace: "pre",
      }}
    />
  </svg>
);

export default LangEn;
