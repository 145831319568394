// import website from '../assets/website.png'
import instagram from '../assets/instagram.png'
// import appstore from '../assets/playstore.png'
// import youtube from '../assets/youtube.jpeg'
// import dribbble from '../assets/dribbble.png'
import github from '../assets/github.png'
import telegram from '../assets/telegram.png'
import telegram_bot from '../assets/telegram_bot.png'
import matrix from '../assets/matrix.png'
import huazhi from '../assets/huazhi.jpg'
import wordpress from '../assets/wordpress.png'
import p2020 from '../assets/2020photo.png'
import breakwall from '../assets/breakwall.jpg'

/*
const items = [{
    "title": "Website",
    "subtitle": "Look at my work!",
    "image": website,
    "link": "https://sagniksahoo.codes" //your personal website or portfolio  link
},
{
    "title": "GitHub",
    "subtitle": "@heysagnik | 🏠 of my open-source projects",
    "image": github,
    "link": "https://github.com/heysagnik" //Github Profile link
},
{
    "title": "Instagram",
    "subtitle": "@heysagnik | Hub of my awesome 😎 pics",
    "image": instagram,
    "link": "https://instagram.com/heysagnik" //instagram profile link 
},
{
    "title": "Twitter",
    "subtitle": "@heysagnik | Don't forget to follow me 😉",
    "image": twitter,
    "link": "https://twitter.com/heysagnik"// twitter profile link 
},
{
    "title": "Apps",
    "subtitle": "Hub of my awesome 🔥 Apps",
    "image": appstore,
    "link": "#"//
},
{
    "title": "YouTube",
    "subtitle": "CODER SAGNIK | Official channel of mine",
    "image": youtube,
    "link": "https://www.youtube.com/channel/UCOUa9hvd4sJWQWQRIQctbSg"//youtube channel link 
},

{
    "title": "Dribbble",
    "subtitle": "@virtuonic | Hub to the Shots of my 😎 apps ",
    "image": dribbble,
    "link": "https://dribbble.com/virtuonic" // Dribbble profile link 
},
{
    "title": "Telegram",
    "subtitle": "@heysagnik | Chat with me instantly, Don't hesitate! ",
    "image": telegram,
    "link": "https://telegram.me/heysagnik" //Telegram Pofile 
}]
*/

const items = [
    {
        "title": "Instagram",
        "title_zh": "Instagram",
        "subtitle": "@mykeyvans | Personal life shares (private)",
        "subtitle_zh": "@mykeyvans | 发一下照片（私人）",
        "image": instagram,
        "link": "https://instagram.com/mykeyvans" //instagram profile link 
    },
    {
        "title": "GitHub",
        "title_zh": "GitHub",
        "subtitle": "@etnperlong | 🏠 of my open-source projects",
        "subtitle_zh": "@etnperlong | 🏠 我的开源项目",
        "image": github,
        "link": "https://github.com/etnperlong" //Github Profile link
    },
    {
        "title": "Telegram Channel",
        "title_zh": "Telegram 频道:",
        "subtitle": "@MyTricksCollection | A channel for sharing my tricks",
        "subtitle_zh": "一个用来乱发东西的频道 aka 画质提升了！",
        "image": huazhi,
        "link": 'https://t.me/MyTricksCollection'
    },
    {
        "title": "2020.Photo",
        "title_zh": "2020.Photo",
        "subtitle": "My self-hosted photo album website to save past memories",
        "subtitle_zh": "我的相册",
        "image": p2020,
        "link": 'https://2020.photo/'
    },
    {
        "title": "Blog: Antony In UK",
        "title_zh": "生活博客: Antony In UK",
        "subtitle": "A blog for sharing my new life in the UK",
        "subtitle_zh": "分享在英国开始的新生活",
        "image": wordpress,
        "link": 'https://antonyinuk.com/'
    },
    {
        "title": "Contact Me on Telegram",
        "title_zh": "通过 Telegram 联系我",
        "subtitle": "@MyKeyVans | I'm always happy to talk to you",
        "subtitle_zh": "@MyKeyVans | 时常在线与你联系",
        "image": telegram,
        "link": "https://t.me/MyKeyVans" //Telegram Pofile
    },
    {
        "title": "Contact Me on Telegram via Greeter Bot",
        "title_zh": "通过 Telegram Bot 联系我",
        "subtitle": "@MyGreeterBot | If your account is restricted (+86)",
        "subtitle_zh": "@MyGreeterBot | 如果你的账户使用 +86 电话号码注册",
        "image": telegram_bot,
        "link": "https://t.me/MyGreeterBot" //Telegram Pofile
    },
    {
        "title": "Contact Me on Matrix",
        "title_zh": "通过 Matrix 联系我",
        "subtitle": "Start a secure chat instantly",
        "subtitle_zh": "与我开始极致安全的聊天",
        "image": matrix,
        "link": "https://matrix.to/#/@me:mykeyvans.space" //Telegram Pofile
    },
    {
        "title": "Sponsor: Project-Cyber Proxy/VPN",
        "title_zh": "推广：乘云计划 加密代理/VPN",
        "subtitle": "An encrypted, secure and fast proxy to pass through Internet barrier. All platform supported! Basic plan starts from RMB 15 per month",
        "subtitle_zh": "安全，快速，尊重用户隐私的加密代理，PC/macOS/iOS/Android全平台可用，低至￥15一月",
        "image": breakwall,
        "link": "https://dash.project-cyber.space/auth/register?code=6EO5agJYwrw"
    }
]

export default items
